export default function() {

    const serializeForm = function (form) {
        var obj = {};
        var formData = new FormData(form);
        for (var key of formData.keys()) {
            obj[key] = formData.get(key);
        }
        return obj;
    };
    const form = document.querySelector('.submit_form')
    if ( form ) {
        form.addEventListener("submit",(event) => {
            event.preventDefault()
            const url = form.getAttribute("action")
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(serializeForm(form))
            }).then(res=>res.json())
            .then(res => console.log(res));
            document.querySelector('fieldset').setAttribute("disabled","disabled")
            document.querySelector('.send-form').innerHTML = "Thank you"
            document.querySelector('.send-form').classList.add("non_clickable")

        })
    }
}