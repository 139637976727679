import gsap from 'gsap';

export default function() {
  const images = Array.from(document.querySelectorAll('.animated'))
	const itemDelay = .15;
	images.forEach(function(img) {
	    	gsap.to(img,{
	    		autoAlpha: 0,
	    		duration: 0
	    })
	})
    function handleIntersection(entries) {
        let itemLoad = 0;
       entries.map((entry) => {
           if (entry.isIntersecting) {
            
              gsap.to(entry.target,{
                    autoAlpha: 1,
                    duration: 1,
                    delay: itemLoad*itemDelay
              })
             
             observer.unobserve(entry.target)
             itemLoad ++
         }
       });
     }
     const observer = new IntersectionObserver(handleIntersection);
     images.forEach(image => observer.observe(image));
}