export default function() {
	const header = document.querySelector('.header')
	const header_menu_btn = document.querySelector('.header_menu_btn:not(.not-hide)')
	
	document.addEventListener('scroll', () => {
		var scrollPos = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
		if (scrollPos > 100){
			header.classList.add('reduced')
			if (header_menu_btn){
				header_menu_btn.classList.add('is-active')
			}
			
		} else {
			header.classList.remove('reduced')
			if (header_menu_btn){
				header_menu_btn.classList.remove('is-active')
			}
		}
	})

	
}