
import '../../scss/application.scss'
// import Flickity from 'flickity'
import Inter from './components/inter.js'
import Header from './components/header.js'
import Popup from './components/popup.js'
import Form from './components/form.js'
import Fullscreen from './components/fullscreen.js'
import Browserdetect from './components/browserdetect.js'
import Menu from './components/menu.js'
require('intersection-observer');

function App() {
    const elem = document.querySelector('.main-carousel');
    // if (elem ) {
    //     const flkty = new Flickity( elem, {
    //         contain: true,
    //         lazyLoad: true});
    // }
    Browserdetect()
    Header()
    Inter()
    Form()
    Popup()
    Fullscreen()
    Menu()

}

document.addEventListener('DOMContentLoaded', () => {
    App() 
})