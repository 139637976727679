export default function(){
	const toggle_menu = document.querySelector('.js-open-menu')
	const menu_mobile = document.querySelector('.header_menu')
	toggle_menu.addEventListener('click', () => {
		menu_mobile.classList.toggle('is-active')
		if(menu_mobile.classList.contains('is-active')){
			toggle_menu.innerHTML = 'CLOSE'
			document.querySelector('html').style = 'overflow: hidden; touch-action:none;'
			document.querySelector('body').style = 'overflow: hidden;'
		} else {
			toggle_menu.innerHTML = 'MENU'
			document.querySelector('html').style = 'overflow: unset; touch-action:unset;'
			document.querySelector('body').style = 'overflow: unset;'
		}
	})
}