export default function() {
    const btn = document.querySelector('.js-open-pop')
    if ( btn ) {
        const close = document.querySelector('.js-close-pop')
        const pop = document.querySelector('.popup')
        btn.addEventListener("click",() => {
            pop.classList.add("open")
        })
        close.addEventListener("click",() => {
            pop.classList.remove("open")

        })
    }
}