export default function() {
    let browser = "";
    let c = navigator.userAgent.search("Chrome");
    let f = navigator.userAgent.search("Firefox");
    let m8 = navigator.userAgent.search("MSIE 8.0");
    let m9 = navigator.userAgent.search("MSIE 9.0");
    if (c > -1) {
        // document.querySelector('body').classList.add('is-chrome')
    } else if (f > -1) {
        document.querySelector('body, html').classList.add('is-firefox')
    } else if (m9 > -1) {
        document.querySelector('body, html').classList.add('is-ie')
    } else if (m8 > -1) {
        document.querySelector('body, html').classList.add('is-ie')
    }

    var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
	if (isSafari) { 
	  document.querySelector('body, html').classList.add('is-safari')
	  // document.querySelector('.mobile-submenu').classList.add('is-safari')
	};

	if(window.navigator.userAgent.indexOf("Edg") > -1){
		document.querySelector('body, html').classList.add('is-edge')
	}
}
