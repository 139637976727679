import gsap from 'gsap';

export default function(){

	const modal = document.querySelector('.js-fullscreen-modal')
	const images = document.querySelectorAll('.shop_detail_images_single')
	
	if (modal && images){
		const modalImage = document.querySelector('.js-fullscreen-modal-image')
		const imagesList = JSON.parse(document.querySelector('.js-images-list').dataset.images)
		const prev = document.querySelector('.prev-image')
		const next = document.querySelector('.next-image')
		const closeModal = document.querySelector('.js-close-modal')
	
		let ind = 0
		images.forEach( i => {
			i.addEventListener('click',  () => {
				modal.classList.add('is-active')
				gsap.to(modal,{
			        autoAlpha: 1,
			        display: 'flex',
			        duration: 0.3 })
				ind = i.dataset.index
				let url = imagesList[ind]
				document.querySelector('html').style = 'overflow: hidden; touch-action:none;'
				document.querySelector('body').style = 'overflow: hidden;'
				modalImage.setAttribute('src', url)
			})
		})

		prev.addEventListener('click', () => {
			if(ind > 0){
				ind--
			} else {
				ind = imagesList.length - 1
			}
			let url = imagesList[ind]
			modalImage.setAttribute('src', url)
			
		})

		next.addEventListener('click', () => {
			if(ind >= imagesList.length - 1){
				ind = 0
			} else {
				ind++
			}
			let url = imagesList[ind]
			modalImage.setAttribute('src', url)
			
		})


		document.addEventListener('keydown', (e) => {
			if (e.key === "Escape" || e.keyCode === 27) {
			  	modal.classList.remove('is-active') 
			  	document.querySelector('html').style = 'overflow: scroll; touch-action:unset;'
				document.querySelector('body').style = 'overflow: scroll;'
			  	gsap.to(modal,{
				        autoAlpha: 0,
				        display: 'none',
				        duration: 0.3
				  })
			  }
		} )

		closeModal.addEventListener('click', () => {
			modal.classList.remove('is-active')
			document.querySelector('html').style = 'overflow: scroll; touch-action:unset;'
			document.querySelector('body').style = 'overflow: scroll;'
			gsap.to(modal,{
		        autoAlpha: 0,
		        display: 'none',
		        duration: 0.3 })
		})

	}
	
}